import Poster from '@adobe/exc-sdk/dist/production/services/PostMessage/Poster';
import 'babel-polyfill';
import React from 'react';
import path from 'path';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Provider from '@react/react-spectrum/Provider';
import Wait from '@react/react-spectrum/Wait';
import { l10n } from '@analytics/l10nify';
import Dialog from '@react/react-spectrum/Dialog';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import * as Toast from '@react/react-spectrum/Toast';
import { SegmentBuilder, SegmentList, UIError, AnsibleActions, getQueryParams } from '@ansible/ansible-sdk';
import envVariable from './envVariable';
import windowPostAction from './windowPostAction';

import './App.css';
import OmegaPageProvider from './OmegaPageProvider';

const App = props => (
  <Provider className="App">
    <Router>
      <Switch>
        <Route
          path="/:imsOrgId([a-zA-Z0-9]+@[a-zA-Z0-9]+)"
          render={routeProps => <OrgRoute {...props} {...routeProps} imsOrgId={routeProps.match.params.imsOrgId} />}
        />
        <Route
          path="/:encodedImsOrgId([a-zA-Z0-9]+%40[a-zA-Z0-9]+)"
          render={routeProps => {
            const { location } = routeProps;
            const { encodedImsOrgId } = routeProps.match.params;
            const imsOrgId = decodeURIComponent(encodedImsOrgId);
            const pathname = location.pathname.replace(encodedImsOrgId, imsOrgId);

            return <Redirect to={{ ...location, pathname }} />;
          }}
        />
        <Route render={() => <UIError message={l10n`Invalid/missing Organization ID.`} />} />
      </Switch>
    </Router>
  </Provider>
);

App.propTypes = {
  authService: PropTypes.object.isRequired,
  featureFlags: PropTypes.array,
  locale: PropTypes.string,
  user: PropTypes.object.isRequired,
};

App.defaultProps = {
  featureFlags: [],
  locale: 'en_US',
};

export default App;

// Routes...

const OrgRoute = ({ location, match, ...restProps }) => {
  const { authService, featureFlags, imsOrgId } = restProps;

  return (featureFlags.includes('poster') || authService.userHasAccessToOrg(imsOrgId)) ? (
    <Switch>
      <Route
        path={`${match.url}/:sandboxName/segments`}
        render={routeProps => <SegmentsRoute {...restProps} {...routeProps} sandboxName={decodeURIComponent(routeProps.match.params.sandboxName)} />}
      />
      <Route
        path={`${match.url}/segments`}
        render={routeProps => <SegmentsRoute {...restProps} {...routeProps} />}
      />
      <Route render={() => <UIError message={l10n`404`} />} />
    </Switch>
  ) : (
    <UIError
      message={l10n`The Organization that is currently selected does not have access to this feature.\n\nPlease contact the Administrator of the Organization.`}
    />
  );
};

const SegmentsRoute = ({ match, ...restProps }) => (
  <Switch>
    <Route path={`${match.url}/:segmentId`} render={routeProps => <SegmentRoute {...restProps} {...routeProps} segmentId={decodeURIComponent(routeProps.match.params.segmentId)} />} />
    <Route path={`${match.url}`} render={routeProps => <BrowseRoute {...restProps} {...routeProps} />} />
  </Switch>
);

const showError = (title, message) => {
  const dialog = (
    <Dialog
      title={title}
      confirmLabel={l10n`Ok`}
      size="S"
      variant="error"
      data-test-id="parse-failure-dialog"
    >
      <span>{l10n(message)}</span>
    </Dialog>
  );

  ModalContainer.show(dialog, this);
};

const BrowseRoute = props => {
  const {
    history,
    imsOrgId,
    location,
    match,
    sandboxName,
    user,
    locale,
  } = props;

  const { disableCreate, disableSelection, hideHeader, hideFilter, singleSelection, queryIn, pageIn, selectionIn } = getQueryParams();
  const additionalParams = {
    disableCreate: disableCreate === 'true', //since these are strings 'false' will evaluate to true
    singleSelection: singleSelection === 'true',
    disableSelection: disableSelection !== 'false', //default to disableSelection unless explicitly set to false.
    hideHeader: hideHeader === 'true', //since these are strings 'false' will evaluate to true
    hideFilter: hideFilter === 'true', //since these are strings 'false' will evaluate to true
    queryIn,
    pageIn,
  };

  if (selectionIn) {
    try {
      const selection = JSON.parse(selectionIn);
      additionalParams.selectionIn = selection;
    } catch (e) {
      showError(l10n('Failed to Parse Query Parameter'), l10n('The selectionIn failed to parse.'));
    }
  }

  const { authToken, imsUserProfile: { userId } } = user;

  const handleCreateClick = () => {
    windowPostAction('createNewSegment');
    history.push({ pathname: path.join(match.path, 'new'), search: location.search });
  };

  const handleEditClick = segment => {
    windowPostAction('editSegment', segment.id);
    history.push({ pathname: path.join(match.path, segment.id), search: location.search });
  };

  const handleAddClick = segments => {
    windowPostAction('addSegment', segments);
  };

  const handleCancel = () => {
    windowPostAction('cancel');
  };

  return (
    <OmegaPageProvider
      pageName="segmentList"
      feature="segmentPicker"
      user={user}
      imsOrgId={imsOrgId}
      locale={locale}
    >
      <SegmentList
        authToken={authToken}
        env={envVariable.REACT_APP_ANSIBLE_ENV}
        imsOrgId={imsOrgId}
        onCancelClick={handleCancel}
        onCreateAudienceClick={handleCreateClick}
        onSegmentEditClick={handleEditClick}
        onAddClick={handleAddClick}
        sandboxName={sandboxName}
        style={{ zIndex: 10 }}
        userId={userId}
        {...additionalParams}
      />
    </OmegaPageProvider>
  );
};

class SegmentRoute extends React.Component {
  constructor() {
    super();
    this.poster = null;
    this.state = {
      receivedImsMessage: false,
      receivedUIConfigMessage: false,
      imsToken: null,
      uiConfig: {},
    };
  }

  handleSave = segment => {
    windowPostAction('save', segment);
    this.handleLeavePage();

    setTimeout(() => {
      Toast.success(l10n`Segment "${segment.name}" saved.`);
    }, 750);
  };

  handleCancel = () => {
    windowPostAction('cancel');
    this.handleLeavePage();
  };

  handleSaveError = message => {
    showError(l10n('Failed to save'), l10n(message));
  };

  handleLeavePage = () => {
    const { history, location } = this.props;
    const { pathname } = location;
    const pathnameWithoutTrailingSlash = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
    const pathAfterCancel = pathnameWithoutTrailingSlash.split('/').slice(0, -1).join('/');

    history.push({ ...location, pathname: pathAfterCancel });
  };

  onSegmentValidityChange = isSegmentValid => {
    this.poster.send('set:isSegmentValid', isSegmentValid);
  };

  getSegment = async message => {
    const retPayload = await AnsibleActions.getSegment();
    message.respond(retPayload);
    return retPayload;
  };

  getSegmentExpressionPql = async message => {
    const retPayload = await AnsibleActions.getSegmentPQL();
    message.respond(retPayload);
    return retPayload;
  };

  saveSegment = async () => {
    await AnsibleActions.save();
  };

  setSegment = async message => {
    await AnsibleActions.loadSegment(message.data);
  };

  getSegmentHumanReadable = async message => {
    const retPayload = await AnsibleActions.getHumanReadable();
    message.respond(retPayload);
    return retPayload;
  };

  componentDidMount() {
    if (this.props.featureFlags.includes('poster')) {
      this.poster = new Poster(window.parent, '*');
      this.poster.registerMessageHandlers('action:save', this.saveSegment);
      this.poster.registerMessageHandlers('get:segment', this.getSegment);
      this.poster.registerMessageHandlers('get:segment:expression:pql', this.getSegmentExpressionPql);
      this.poster.registerMessageHandlers('get:segment:humanReadable', this.getSegmentHumanReadable);
      this.poster.registerMessageHandlers('set:segment', this.setSegment);
      this.poster.registerMessageHandlers('set:authToken', this.setAuthToken);
      this.poster.registerMessageHandlers('set:ui:config', this.setConfig);
    }
  }

  setAuthToken = incoming => {
    this.setState({
      imsToken: incoming.data,
      receivedImsMessage: true,
    });
  };

  setConfig = incoming => {
    this.setState({
      uiConfig: incoming.data,
      receivedUIConfigMessage: true,
    });
  };

  renderWithPoster() {
    const {
      imsOrgId, featureFlags, locale, sandboxName, segmentId, user,
    } = this.props;
    const { imsToken, receivedImsMessage, receivedUIConfigMessage } = this.state;

    if (!receivedImsMessage || !receivedUIConfigMessage) {
      return <Wait className="top-spinner" size="L" centered />;
    }

    return (
      <OmegaPageProvider
        pageName="segmentBuilder"
        feature="segmentBuilder:renderWithPoster"
        user={user}
        imsOrgId={imsOrgId}
        locale={locale}
      >
        <SegmentBuilder
          authToken={imsToken}
          config={this.state.uiConfig}
          featureFlags={featureFlags}
          env={envVariable.REACT_APP_ANSIBLE_ENV}
          imsOrgId={imsOrgId}
          onCancel={this.handleCancel}
          onSave={this.handleSave}
          onSaveError={this.handleSaveError}
          sandboxName={sandboxName}
          segmentId={segmentId === 'new' ? null : segmentId}
          onSegmentValidityChange={this.onSegmentValidityChange}
          locale={locale}
        />
      </OmegaPageProvider>
    );
  }

  renderWithImsLib() {
    const {
      user, imsOrgId, featureFlags, locale, sandboxName, segmentId,
    } = this.props;

    const { authToken } = user;
    const { imsUserProfile: { userId } } = user;

    if (!authToken) {
      return <Wait className="top-spinner" size="L" centered />;
    }

    return (
      <OmegaPageProvider
        pageName="segmentBuilder"
        feature="segmentBuilder:renderWithPoster"
        user={user}
        imsOrgId={imsOrgId}
        locale={locale}
      >
        <SegmentBuilder
          authToken={authToken}
          featureFlags={featureFlags}
          env={envVariable.REACT_APP_ANSIBLE_ENV}
          imsOrgId={imsOrgId}
          locale={locale}
          onCancel={this.handleCancel}
          onSave={this.handleSave}
          onSaveError={this.handleSaveError}
          sandboxName={sandboxName}
          segmentId={segmentId === 'new' ? null : segmentId}
          userId={userId}
        />
      </OmegaPageProvider>
    );
  }

  render() {
    const { featureFlags } = this.props;

    if (featureFlags.includes('poster')) {
      return this.renderWithPoster();
    }

    return this.renderWithImsLib();
  }
}
