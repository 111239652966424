/**
 * This file abstracts the retrieval of environment variables in order to
 * replace them at run time. This is done because the project has build variables which are different for
 * each env but the deployment pipeline only allows for a single set of build vars. This is our hack. Enjoy :)
 */

const envVariables = {
  REACT_APP_ANSIBLE_ENV: 'process.env.REACT_APP_ANSIBLE_ENV',
  REACT_APP_IMS_ENV: 'process.env.REACT_APP_IMS_ENV',
  REACT_APP_FEATURE_FLAGS: 'process.env.REACT_APP_FEATURE_FLAGS',
  REACT_APP_IMS_CLIENT_ID: 'process.env.REACT_APP_IMS_CLIENT_ID',
  REACT_APP_CATALOG_BASE_URL: 'process.env.REACT_APP_CATALOG_BASE_URL',
  REACT_APP_PREDICATE_STORE_BASE_URL: 'process.env.REACT_APP_PREDICATE_STORE_BASE_URL',
  REACT_APP_PROFILE_ACCESS_BASE_URL: 'process.env.REACT_APP_PROFILE_ACCESS_BASE_URL',
  REACT_APP_PROFILE_PREVIEW_BASE_URL: 'process.env.REACT_APP_PROFILE_PREVIEW_BASE_URL',
};

const proxyHandler = {
  get(target, key) {
    if (target[key] !== undefined) {
      return process.env[key] || target[key];
    } else if (typeof key === 'symbol') {
      return Object.getOwnPropertySymbols(target)[key];
    }

    return undefined;
  },
};

export default new Proxy(envVariables, proxyHandler);
