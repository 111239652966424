/**
 * Posts a messages in a way such that if this app is iFramed, the parent window can receive messages
 *
 * {
 *   action: 'action',
 *   data: { some: 'data' }
 * }
 *
 * @param action - String (required) - An action/type for this message
 * @param data - Serializable data (optional) - The data for this message
 */
export default (action, data) => {
  if (!action) {
    return;
  }

  const payload = {
    action,
  };

  if (data) {
    payload.data = data;
  }

  window.parent.postMessage(payload, '*');
};
