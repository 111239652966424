import 'react-app-polyfill/ie11'; // For IE 11 support
import React from 'react';
import ReactDOM from 'react-dom';
import Wait from '@react/react-spectrum/Wait';
import Provider from '@react/react-spectrum/Provider';
import { AuthService, UIError, getQueryParams } from '@ansible/ansible-sdk';
import path from 'path';

import App from './App';
import { unregister } from './registerServiceWorker';
import envVariable from './envVariable';

import './index.css';
import { loadAppMeasurement } from './OmegaPageProvider';

const queryParams = getQueryParams();

// todo: temporary. remove this when campaign updates how they deep link
if (queryParams.imsOrgId) {
  const predicateId = queryParams.predicateId || 'new';
  const urlPath = path.join(queryParams.imsOrgId, 'segments', predicateId);
  const filteredQueryParams = Object
    .keys(queryParams)
    .filter(key => key !== 'imsOrgId' && key !== 'predicateId')
    .map(key => `${key}=${queryParams[key]}`);

  window.location.href = `${window.location.origin}/${urlPath}?${filteredQueryParams}`;
}

ReactDOM.render(<Provider className="App"><Wait centered size="L" /></Provider>, document.getElementById('root'));

(async () => {
  unregister();

  try {
    const featureFlagsParam = getQueryParams().featureFlags || '';
    let authService = null;
    let user = null;

    if (!featureFlagsParam.includes('poster')) {
      authService = new AuthService(envVariable.REACT_APP_IMS_ENV);
      user = await authService.getUser();
    }

    loadAppMeasurement();

    const envFeatureFlags = envVariable.REACT_APP_FEATURE_FLAGS || '';
    const featureFlags = Array.concat(featureFlagsParam.split(','), envFeatureFlags.split(','));

    ReactDOM.render(
      <App user={user} authService={authService} featureFlags={featureFlags} locale={getQueryParams().locale} />
      , document.getElementById('root'),
    );
  } catch (error) {
    ReactDOM.render(<Provider className="App"><UIError message={error.message} /></Provider>, document.getElementById('root'));
  }
})();
