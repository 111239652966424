import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import envVariable from './envVariable';

export const loadAppMeasurement = () => {
  const head = document.getElementsByTagName('head')[0];
  const js = document.createElement('script');
  js.type = 'text/javascript';
  if (envVariable.REACT_APP_ANSIBLE_ENV === 'prod') {
    js.src = '//assets.adobedtm.com/d4d114c60e50/91e482548f23/launch-c6c6af24e9ee.min.js';
  } else {
    js.src = '//assets.adobedtm.com/d4d114c60e50/91e482548f23/launch-404214aee956-development.min.js';
  }
  head.appendChild(js);
};

const OmegaPageProvider = props => {
  const {
    pageName, feature, user, imsOrgId, locale = '',
  } = props;

  if (_isNil(_get(window, 'digitalData', null))) {
    window.digitalData = {};
  }

  window.digitalData.user = {
    id: _get(user, 'imsUserProfile.userId', 'missing_user_id'),
    corpId: imsOrgId || 'missing_imsOrg_id',
    authSystem: 'ims',
    corpName: imsOrgId,
    accountType: 'user',
    language: locale.replace('_', ':'),
  };
  if (_isNil(_get(window, 'digitalData.page', null))) {
    window.digitalData.page = {
      solution: {
        name: 'ansible',
        version: 'iframe',
      },
    };
  }
  window.digitalData.page = {
    hierarchy: 'ansibleiframe',
    name: pageName,
    url: window.location.href,
    referringUrl: document.referrer,
    feature,
  };

  if (window._satellite) {
    window._satellite.track('page');
  }

  return props.children;
};

export default OmegaPageProvider;
